import axios from 'axios';

// Crear instancia de Axios
export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_UR,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor de solicitudes
apiClient.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem('access_token');

        // Verifica si el token está cerca de expirar
        if (token && isTokenExpired(token)) {
            try {
                // Obtener nuevo token usando refresh token
                token = await refreshAccessToken();
                localStorage.setItem('access_token', token);
            } catch (error) {
                console.error('Error refrescando el token:', error);
                // Manejo de error: redireccionar a la página de login si el refresh falla
                window.location.href = '/login';
            }
        }
        // Agregar el token a los headers de la solicitud
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Función que verifica si el token ha expirado
const isTokenExpired = (token) => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = (payload.exp * 1000);
    const now = new Date().getTime();

    const timeRemaining = expiry - now;
    // console.log("validate time", timeRemaining <= 60000 , timeRemaining > 0, timeRemaining <= 60000 && timeRemaining > 0);
    // console.log("diference", timeRemaining, expiry, now);
    if(timeRemaining < 0){
        localStorage.clear();
        window.location.href = '/'
    }
    return timeRemaining < 60000 ;
};

// Función para refrescar el access token
const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem('refresh_token');
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/refresh');
    
    if (response.data.access_token) {
        return response.data.access_token;
    } else {
        throw new Error('Error obteniendo nuevo access token');
    }
};

//  default apiClient;