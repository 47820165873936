import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        title: "",
        isOpen: false,
        bodyType: "", // Ahora guarda un string, NO una referencia de React
        size: "",
        extraObject: {},
        isClosed: false,
    },
    reducers: {
        openModal: (state, action) => {
            const { title, bodyType, extraObject, size } = action.payload;
            state.isOpen = true;
            state.bodyType = bodyType; // Solo el nombre del componente
            state.title = title;
            state.size = size || "lg";
            state.extraObject = extraObject;
        },

        closeModal: (state) => {
            state.isOpen = false;
            state.bodyType = "";
            state.title = "";
            state.extraObject = {};
            state.isClosed = true;
        },
        
    }
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
