import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { lazy, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { themeChange } from 'theme-change';
import { checkAuth, getAccessToken, isAuthenticated } from './app/auth';
import initializeApp from './app/init';
import Reports from './features/reports';
import { fetchNotifications } from './data'; // Asegúrate de que esta función esté configurada correctamente
import { setnoOfNotifications } from './features/common/headerSlice';
import './App.css';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
// const Register = lazy(() => import('./pages/Register'));

// Initializing different libraries
initializeApp();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Inicializa los temas de DaisyUI
    themeChange(false);

    // Verifica si el usuario está autenticado
    const verifiedLogin = localStorage.getItem('verifiedLogin') === 'true';

    if (verifiedLogin) {
      const fetchData = async () => {
        try {
          const response = await fetchNotifications();
          const notifications = response;

          // Actualiza el estado global con el número de notificaciones
          dispatch(setnoOfNotifications({ noOfNotifications: notifications.length }));
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      // Llama a fetchData inicialmente y configura un intervalo
      fetchData();
      const intervalId = setInterval(fetchData, 50000);

      // Limpia el intervalo al desmontar
      return () => clearInterval(intervalId);
    }
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to={checkAuth() ? '/app/home' : '/login'} replace />} />
          {checkAuth() ? (
            <Route path="/app/*" element={<Layout />} />
          ) : (
            <Route path="/app/*" element={<Navigate to="/login" replace />} />
          )}
          <Route path="/app/reports/:id" element={<Reports />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
