import CryptoJS from 'crypto-js';

export function cryp_pass(password) {

    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_CRYPT);
    
    const iv = CryptoJS.lib.WordArray.random(16);

    const passcryp = CryptoJS.AES.encrypt(password, key, { iv: iv }).toString();

    const iv_token = iv.toString(CryptoJS.enc.Base64)

    return {passcryp, iv_token}
}

export function encrypt(data){
    // Iterar sobre los usuarios y encriptar las contraseñas
    const encryptedData = data.map(space => {
        const wordArray = CryptoJS.enc.Utf8.parse(space.password);
        const encryptedPass = CryptoJS.enc.Base64.stringify(wordArray);
        return {
            user: space.id,
            pass: encryptedPass
        };
    });
    return encryptedData;
};