
import { encrypt } from './utils/cryp';
import { apiClient } from './app/axiosConf'
import { logDOM } from '@testing-library/react';

const API_BASE = process.env.REACT_APP_BACKEND_URL;



export const fetchNotifications = async () => { 
    try { 
        // Envía los datos al backend 
        const response = await apiClient.get(API_BASE + "/notifications/notification");
        return response.data; 
    } catch (error) { 
        console.error('Error in request', error); 
        throw error;
    }
};